.imageContainer {
  display: flex;
  align-items: center;
  position: relative;
}

/* Add the animation only when 'liked' is true */
.thumb {
  transform-origin: bottom left;
  z-index: 1;

  /* Add animation class conditionally */
  &.liked {
    animation: like .8s;
  }
}

@keyframes like {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
