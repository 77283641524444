/* ToggleContainer.module.css */
.toggleContainer {
  animation: pulse-shadow 3s ease-in-out infinite;
}

.toggleContainer::before {
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: left 0.65s ease-in-out; /* Smooth transition */
}

.toggleContainer:hover::before {
  left: 100%;
  animation: shine 1.5s ease-in-out; /* Activate shine on hover */
}

/* Keyframes for the shine effect on hover */
@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

/* Keyframes for the pulsing shadow effect */
@keyframes pulse-shadow {
  0% {
    box-shadow: 1px 1px 25px 10px rgba(255, 230, 0, 0.5);
  }
  50% {
    box-shadow: 1px 1px 25px 10px rgba(255, 230, 0, 0.2);
  }
  100% {
    box-shadow: 1px 1px 25px 10px rgba(255, 230, 0, 0.5);
  }
}
